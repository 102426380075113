import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../../components/seo';
import PropTypes from 'prop-types';
import Logo from 'common/src/assets/image/hosting/logo.svg';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import BackgroundImage from 'common/src/assets/image/hosting/Background/back_circle 1.svg';
import BackgroundImage2 from 'common/src/assets/image/hosting/Background/CTA.svg';

// import "./web.css";
const Webinarpage = ({ row, col, col1 }) => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        {/* <SEO
          title="Webinar"
          description="Rapid Acceleration Partners provides practical AI and Intelligent RPA solutions. Extract structured data from unstructured content. Book a demo here."
        /> */}
        <Helmet>
          <title>Achieving Peak Efficiencies in Mortgage Processing</title>
          <meta
            name="description"
            content="Achieving Peak Efficiencies in Mortgage Processing"
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper className="HomePage">
          <Wrapper>
            <div className="thankyou-holder">
              <Container>
                <div className="header">
                  <div className="logo">
                    <img src={Logo} alt="rap logo" />
                  </div>
                </div>
              </Container>
            </div>
            <Container>
              <Box {...row}>
                <Box {...col} className="thankyou-text">
                  <h1>
                    <em> THANK YOU</em>
                  </h1>
                </Box>
              </Box>

              <div className="content">
                <Box {...row}>
                  <Box {...col1}>
                    <p>
                      Thank you for registering for this webinar. Be on the
                      lookout for an email with the information you'll need to
                      attend. If you're unable to attend, we will be sending out
                      a copy of the presentation to all registrants at the
                      conclusion of the webcast. See you online soon!
                    </p>
                    <div className="add-to-calendar">
                      <a
                        className="btn btn_tertiary"
                        href="https://add.eventable.com/events/614d74eb86646d60a3732fa2/614d74ef0552cb2b1a6a4acd/"
                        data-event="614d74ef0552cb2b1a6a4acd"
                        target="_blank"
                        data-key="614d74eb86646d60a3732fa2"
                        data-style="2"
                      >
                        <span class="btn-text">Add to Calendar</span>
                      </a>
                    </div>

                    {/* -------------------------------------------------- */}
                    {/* ---------------------FOOTER ----------------- */}
                    {/* -------------------------------------------------- */}
                    <div className="footer">
                      <div class="footer-social-networks">
                        <a
                          href="https://www.linkedin.com/company/rapid-acceleration-partners"
                          target="_blank"
                          className="footer-icons"
                        >
                          <i className="fa fa-linkedin-square"></i>
                        </a>
                        <a
                          href="https://www.facebook.com/rapventures/"
                          target="_blank"
                          className="footer-icons"
                        >
                          <i className="fa fa-facebook-square"></i>
                        </a>
                        <a
                          href="https://twitter.com/rap_ventures"
                          target="_blank"
                          className="footer-icons"
                        >
                          <i className="fa fa-twitter-square"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/rapid_acceleration_partners/"
                          target="_blank"
                          className="footer-icons"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCMSXhWJFm3Vvp1Rmbfa54lw/"
                          target="_blank"
                          className="footer-icons"
                        >
                          <i className="fa fa-youtube-square"></i>
                        </a>
                      </div>

                      <div class="footer-copyright">
                        <span>
                          Copyright © 2022 Rapid Acceleration Partners, Inc.
                        </span>
                      </div>
                    </div>
                  </Box>
                </Box>
              </div>
            </Container>
          </Wrapper>
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
Webinarpage.propTypes = {
  row: PropTypes.object,
};

Webinarpage.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49.99%', '49.99%'],
  },
  col1: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '70%', '70%'],
  },
};
export default Webinarpage;

const Wrapper = styled.section`
  .logo {
    width: 100px;
    img {
      width: 100%;
    }
  }
  .thankyou-holder {
    position: relative;
    margin-bottom: -100px;
    &:before {
      content: '';
      background-image: url(${BackgroundImage2});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
    }
    background-color: #ffeeee;
    min-height: 250px;
    padding: 30px 0px;
  }
  .thankyou-text {
    box-shadow: 0 0 8px rgb(31 57 74 / 25%);
    text-align: center;
    background: #fff;
    border-radius: 15px;
    position: relative;
    z-index: 2;
    h1 {
      font-size: 45px;
    }
  }
  .content {
    min-height: calc(80vh - 150px);
    margin-top: 30px;
    font-size: 18px;
    line-height: 2em;
    text-align: justify;
  }
  .footer {
    margin-top: 100px;
  }
  .footer-social-networks {
    display: flex;
    justify-content: center;
    a {
      color: #eb8392;
      font-size: 30px;
      margin-right: 10px;
    }
  }
  .footer-copyright {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
`;
